import { registerMicroApps, runAfterFirstMounted, setDefaultMountApp, start, initGlobalState } from 'qiankun';
import Keycloak from 'keycloak-js';
import './index.less';

import render from './render/ReactRender';
import UpperLayout from './src/components/UpperLayout';
import keycloakSettings from './keycloak.json';
import mode from './src/components/mode';

const isProduction = mode.isProduction();

const loader = (loading) => render({ loading });

/**
 * This sends activity info to user profile.
 * @function
 * @param {string} token - users keycloak token
 * @param {object} info - description (serviceName required)
 * { 
 *  serviceName:'service',
 *  serviceSection:'section',
 *  actionMessage: 'user enters page',
 *  orgInn: 'organization inn',
 *  orgTitle: 'organization title',
 *  orgPost: 'organization post'
 * }
 */
function v(token, info) {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://lk-profile-api.my1.urfu.ru/activity');
  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify(info));
}

const formatterRegisterMicroApp = (name, port, entryPart) => ({  
  name,  
  entry: name === 'test' ? { scripts: ['test_reg.js'] } : isProduction ? `//${entryPart ? entryPart : name}.my1.urfu.ru` : `http://localhost:${port}`,  
  container: '#subapp-viewport',  
  loader,  
  activeRule: `#/${name}`
});  

const runApp = (ctx) => {
  UpperLayout();

  if (ctx.isAuthenticated()) {
    v(ctx.token(), {
      serviceName: 'lk-shell',
    });
  }

  registerMicroApps(
    [
      formatterRegisterMicroApp('news', 7103),
      formatterRegisterMicroApp('test'),
    ].concat(
      ctx.isAuthenticated()
        ? [
          formatterRegisterMicroApp('ref-app', 7104),
          formatterRegisterMicroApp('catalog', 7102),
          formatterRegisterMicroApp('profile', 7110, 'lk-profile'),
          formatterRegisterMicroApp('vac', 7111),
          formatterRegisterMicroApp('contacts', 7107),
          formatterRegisterMicroApp('org-structure', 7108),
          formatterRegisterMicroApp('icm', 7205),
          formatterRegisterMicroApp('role', 3500, 'lk-role'),
          formatterRegisterMicroApp('role-test', 3500, 'lk-role-test'),
          formatterRegisterMicroApp('rvd', 4200),
          formatterRegisterMicroApp('scimobility', 7788),
          formatterRegisterMicroApp('kpis-npr', 7110),
          formatterRegisterMicroApp('achievements-npr', 7111),
          formatterRegisterMicroApp('notifications', 3500, 'lk-notifications'),
          formatterRegisterMicroApp('notifications-test', 3500, 'lk-notifications-test'),
        ]
        : []
    ),
    {
      beforeLoad: [
        (app) => {
          console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
        },
      ],
      beforeMount: [
        (app) => {
          console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
        },
      ],
      afterUnmount: [
        (app) => {
          console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
        },
      ],
    }
  );

  const { onGlobalStateChange, setGlobalState } = initGlobalState({ user: 'user' });
  onGlobalStateChange((value, prev) => console.log('[onGlobalStateChange - master]:', value, prev));
  setGlobalState({ ignore: 'user', user: { name: 'user' } });

  runAfterFirstMounted(() => {
    console.log('[MainApp] first app mounted');
  });

  start();

  if (!window.location.hash) {
    window.location.hash = window.isAuthenticated() ? '/catalog' : '/news';
  }
}

function initApp(ctx) {
  ctx.isAuthenticated = function () {
    return ctx.keycloak && ctx.keycloak.authenticated;
  };
  ctx.auth = function () {
    return ctx.isAuthenticated() ? ctx.keycloak.idTokenParsed : {};
  };
  ctx.token = function () {
    return ctx.isAuthenticated() ? ctx.keycloak.token : '';
  };
  ctx.userTitle = function (defTitle = ' ') {
    let auth = ctx.auth && ctx.auth();
    return auth && auth.user && auth.user.person
      ? {firstName: auth.user.person.firstName, lastName: auth.user.person.lastName}
      : defTitle;
  };
  ctx.keycloak = new Keycloak(keycloakSettings);
  ctx.keycloak
    .init({ onLoad: 'check-sso', silentCheckSsoRedirectUri: window.location.origin + '/check-sso.html' })
    .then(function () {
      if (!ctx.isAuthenticated()) {
        var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
        eventer(messageEvent, function (e) {
          if (e.data === 'https://my.urfu.ru/home/test') {
            console.log('authentificated');
            window.location = keycloak.createLoginUrl();
          }
        });
        var ifrm = document.createElement('iframe');
        ifrm.setAttribute(
          'src',
          'https://sso.urfu.ru/adfs/ls/?wtrealm=https%3a%2f%2fsk5-cod-lks-prd.at.urfu.ru&wa=wsignin1.0&wreply=https%3A//my.urfu.ru/home/test'
        );
        ifrm.style.position = 'absolute';
        ifrm.style.border = '0';
        ifrm.style.width = '0';
        ifrm.style.height = '0';
        document.body.appendChild(ifrm);
        //  /// testing
        // ifrm.contentWindow.document.write(
        //   '<html><head><script>window.parent.postMessage("https://my.urfu.ru/home/test", "*");</script></head></html>'
        // );
        runApp(ctx);
      } else {
        runApp(ctx);
      }
    })
    .catch(function (err) {
      console.log(err, 'failed to initialize');
    });
}

initApp(window);
// window.isAuthenticated = function () {
//   return true;
// };
// window.userTitle = function () {
//   return 'Осетров Никита';
// };
// runApp();
