import React, { useState, useEffect } from 'react';
import './Header.less';
import urfuLogo from '../img/urfu_logo.svg';
import dotsIcon from '../img/dots_icon.svg';
import userIcon from '../img/user_icon.svg';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import ProfileDropdown from '../components/ProfileDropdown';
import Store from '../components/Store';
import axios from 'axios';
import { NEW_YEAR } from '../../config.js'

export default function Header() {
  const [avatar, setAvatar] = useState();
  const [garlandState, setGarlandState] = useState(true);
  const [switcherState, setSwitcherState] = useState(false);
  useEffect(() => {
    fetchAvatar();
  }, [])
  const count = Math.ceil(window.innerWidth / 800);
  const fetchAvatar = async () => {
    const response = await axios(`https://lk-profile-api.my1.urfu.ru/api/profile/photo`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.keycloak.token}`,
      },
      responseType: 'blob'
    });
    setAvatar(URL.createObjectURL(response.data));
  }
  const handleGarlandSwitcher = () => {
    setSwitcherState(true);
    setTimeout(() => {
      setGarlandState(!garlandState);
      setSwitcherState(false)
    }, "100");
    
    
  }
  const garland = []
  for (let i = 0; i < count; i++) {
    garland.push(
      <svg key={i} className={garlandState ? 'garland' : 'garland garland-off'}>
        <path 
          d='M 5 1, C 5 5, 7 5, 45 3, 
          C 45 3, 25 5, 85 1
          C 85 1, 105 5, 125 1
          C 125 1, 145 7, 165 3
          C 165 3, 185 7, 205 3
          C 205 3, 225 5, 245 1
          C 245 1, 265 7, 285 1
          C 285 1, 305 5, 325 3
          C 325 3, 345 7, 365 3
          C 365 3, 385 5, 405 1
          C 405 1, 425 7, 445 3
          C 445 3, 465 5, 485 1
          C 485 1, 505 7, 525 1
          C 525 1, 545 5, 565 3
          C 565 3, 585 5, 605 3
          C 605 3, 625 7, 645 1
          C 645 1, 665 7, 685 1
          C 685 1, 705 5, 725 3
          C 725 3, 745 7, 765 1
          C 765 1, 785 5, 805 1' 
          stroke='#A7A7A7' 
          fill='transparent'
        />
        <circle cx="5" cy="5" r="5" className='garland-gray'/>
        <circle cx="5" cy="5" r="4" className='garland-white'/>        
        <circle cx="5" cy="5" r="5" className='garland-red'/>
        <circle cx="45" cy="7" r="5" className='garland-gray'/>
        <circle cx="45" cy="7" r="4" className='garland-white'/>        
        <circle cx="45" cy="7" r="5" className='garland-yellow'/>
        <circle cx="85" cy="5" r="5" className='garland-gray'/>
        <circle cx="85" cy="5" r="4" className='garland-white'/>        
        <circle cx="85" cy="5" r="5" className='garland-green'/>
        <circle cx="125" cy="5" r="5" className='garland-gray'/>
        <circle cx="125" cy="5" r="4" className='garland-white'/>        
        <circle cx="125" cy="5" r="5" className='garland-blue'/>
        <circle cx="165" cy="7" r="5" className='garland-gray'/>
        <circle cx="165" cy="7" r="4" className='garland-white'/>        
        <circle cx="165" cy="7" r="5" className='garland-red'/>
        <circle cx="205" cy="7" r="5" className='garland-gray'/>
        <circle cx="205" cy="7" r="4" className='garland-white'/>        
        <circle cx="205" cy="7" r="5" className='garland-yellow'/>
        <circle cx="245" cy="5" r="5" className='garland-gray'/>
        <circle cx="245" cy="5" r="4" className='garland-white'/>        
        <circle cx="245" cy="5" r="5" className='garland-green'/>
        <circle cx="285" cy="5" r="5" className='garland-gray'/>
        <circle cx="285" cy="5" r="4" className='garland-white'/>        
        <circle cx="285" cy="5" r="5" className='garland-blue'/>
        <circle cx="325" cy="7" r="5" className='garland-gray'/>
        <circle cx="325" cy="7" r="4" className='garland-white'/>        
        <circle cx="325" cy="7" r="5" className='garland-red'/>
        <circle cx="365" cy="7" r="5" className='garland-gray'/>
        <circle cx="365" cy="7" r="4" className='garland-white'/>        
        <circle cx="365" cy="7" r="5" className='garland-yellow'/>
        <circle cx="405" cy="5" r="5" className='garland-gray'/>
        <circle cx="405" cy="5" r="4" className='garland-white'/>        
        <circle cx="405" cy="5" r="5" className='garland-green'/>
        <circle cx="445" cy="7" r="5" className='garland-gray'/>
        <circle cx="445" cy="7" r="4" className='garland-white'/>        
        <circle cx="445" cy="7" r="5" className='garland-blue'/>
        <circle cx="485" cy="5" r="5" className='garland-gray'/>
        <circle cx="485" cy="5" r="4" className='garland-white'/>        
        <circle cx="485" cy="5" r="5" className='garland-red'/>
        <circle cx="525" cy="5" r="5" className='garland-gray'/>
        <circle cx="525" cy="5" r="4" className='garland-white'/>        
        <circle cx="525" cy="5" r="5" className='garland-yellow'/>
        <circle cx="565" cy="7" r="5" className='garland-gray'/>
        <circle cx="565" cy="7" r="4" className='garland-white'/>        
        <circle cx="565" cy="7" r="5" className='garland-green'/>
        <circle cx="605" cy="7" r="5" className='garland-gray'/>
        <circle cx="605" cy="7" r="4" className='garland-white'/>        
        <circle cx="605" cy="7" r="5" className='garland-blue'/>
        <circle cx="645" cy="5" r="5" className='garland-gray'/>
        <circle cx="645" cy="5" r="4" className='garland-white'/>        
        <circle cx="645" cy="5" r="5" className='garland-red'/>
        <circle cx="685" cy="5" r="5" className='garland-gray'/>
        <circle cx="685" cy="5" r="4" className='garland-white'/>        
        <circle cx="685" cy="5" r="5" className='garland-yellow'/>
        <circle cx="725" cy="7" r="5" className='garland-gray'/>        
        <circle cx="725" cy="7" r="4" className='garland-white'/>        
        <circle cx="725" cy="7" r="5" className='garland-green'/>
        <circle cx="765" cy="5" r="5" className='garland-gray'/>
        <circle cx="765" cy="5" r="4" className='garland-white'/>        
        <circle cx="765" cy="5" r="5" className='garland-blue'/>
        <circle cx="805" cy="5" r="5" className='garland-gray'/>
        <circle cx="805" cy="5" r="4" className='garland-white'/>        
        <circle cx="805" cy="5" r="5" className='garland-red'/>
      </svg>
    )
  }
  let _close;
  const setClose = (close) => {
    _close = close;
  };
  return (
    <header className="main-header">
      <div className="urfu-logo">
        <a href="https://urfu.ru/ru/" target="_blank">
          <img src={urfuLogo} alt="" />
        </a>
      </div>
      <div className="container-right">
        {/* <Button shape="circle" img={searchIcon} /> */}
        <Dropdown
          setClose={setClose}
          trigger={<Button shape="circle" img={dotsIcon} />}
          content={
            <Store
              handleClick={() => {
                _close && _close();
              }}
            />
          }
        />
        <Dropdown
          setClose={setClose}
          trigger={<Button shape="circle" clazz='avatar' img={!isAuthenticated() && userIcon} auth={isAuthenticated()} title={userTitle()} avatar={avatar} initials/>}
          content={<ProfileDropdown
            avatar={avatar}
            handleClick={() => {
              _close && _close();
            }}
          />}
        />
      </div>
      {NEW_YEAR &&
      <>
        <div className='garland-container'>
          {garland}
        </div>
        <div className='garland-switcher-container'>
          <div className={switcherState ? 'garland-rope' : 'garland-rope garland-rope-long'}></div>
          <div onClick={handleGarlandSwitcher} className={switcherState ? 'garland-switcher' : 'garland-switcher garland-switcher-state'}></div>
        </div>
      </>}
    </header>
  );
}
