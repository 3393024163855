import React from 'react';
import './Store.less';
import StoreTag from './StoreTag'
import { integrationOfModeusCommands } from '../data/PersonsStorage.js'

import newsIcon from '../img/icons/storeIcons/news.svg';
import catalogueIcon from '../img/icons/storeIcons/catalog.svg';
import profileIcon from '../img/icons/storeIcons/profile.svg';
import vacIcon from '../img/icons/storeIcons/vac.svg';
import orgStructureIcon from '../img/icons/storeIcons/orgstructure.svg';
import priorityIcon from '../img/icons/storeIcons/priority.svg';
import modeusIcon from '../img/icons/storeIcons/modeus.svg';
import universityIcon from '../img/icons/storeIcons/univer.svg';
import brsIcon from '../img/icons/storeIcons/brs.svg';
import directSessions from '../img/icons/storeIcons/principal.svg';
import additionalEducation from '../img/icons/storeIcons/addeducation.svg';
import office365 from '../img/icons/storeIcons/365.svg';
import techSupport from '../img/icons/storeIcons/tech-support.svg';
import electronicLibrary from '../img/icons/storeIcons/library.svg';
import finance from '../img/icons/storeIcons/finance.svg';
import remote from '../img/icons/storeIcons/science-res.svg';
import contactsIcon from '../img/icons/storeIcons/contacts.svg';
import lkRole from '../img/icons/storeIcons/lk-role.svg';
import rvdIcon from '../img/icons/storeIcons/rvd.svg';
import scimobilityIcon from '../img/icons/storeIcons/scimobility.svg';
import impersonationIcon from '../img/icons/storeIcons/impersonation.svg';
import achievementsNprIcon from '../img/icons/storeIcons/achievements-npr.svg';
import moodleIcon from '../img/icons/storeIcons/moodle.svg';
import requestDPOIcon from '../img/icons/storeIcons/requestDPO.svg';
import OPSiteManageIcon from '../img/icons/storeIcons/OPSiteManage.svg';
import WorkingModulesIcon from '../img/icons/storeIcons/WorkingModules.svg';
import corpMailIcon from '../img/icons/storeIcons/corpMail.svg';
import PortfolioManagementIcon from '../img/icons/storeIcons/PortfolioManagement.svg';
import hupermethodIcon from '../img/icons/storeIcons/hupermethod.svg';
import antivirusIcon from '../img/icons/storeIcons/antivirus.svg';
import bbbIcon from '../img/icons/storeIcons/bbb.svg';
import folderIcon from '../img/icons/storeIcons/folders.svg';
import wifiIcon from '../img/icons/storeIcons/wifi.svg';
import antiplagiatIcon from '../img/icons/storeIcons/antiplagiat.svg';
import periodicalsIcon from '../img/icons/storeIcons/periodicals.svg';
import subscriptionIcon from '../img/icons/storeIcons/subscription.svg';
import librarySurveysIcon from '../img/icons/storeIcons/librarySurveys.svg';
import notificationsIcon from '../img/icons/storeIcons/notifications.svg';
import projectIcon from '../img/icons/storeIcons/project.svg';
import practiceIcon from '../img/icons/storeIcons/practice.svg';
import analyticsIcon from '../img/icons/storeIcons/analytics.svg';
import emailIcon from '../img/icons/storeIcons/email.svg';
import licenseIcon from '../img/icons/storeIcons/license.svg';
import scienceIcon from '../img/icons/storeIcons/science.svg';
import publicationsMonitoringIcon from '../img/icons/storeIcons/publications_monitoring.svg';
import publicationsIcon from '../img/icons/storeIcons/publications.svg';
import EBSCOIcon from '../img/icons/storeIcons/EBSCO.svg';
import InnoNewsIcon from '../img/icons/storeIcons/inno-news.svg'
import AceleratorIcon from '../img/icons/storeIcons/acelerator.svg'
import techIcon from '../img/icons/storeIcons/tech.svg'
import startupAsDiplomaIcon from '../img/icons/storeIcons/startupAsDiploma.svg'
import startupUrfuIcon from '../img/icons/storeIcons/startupUrfu.svg'
import copyrightIcon from '../img/icons/storeIcons/copyright.svg'
import centerIcon from "../img/icons/storeIcons/icon_center.svg"
import ssNPR from "../img/icons/storeIcons/ssnpr.svg"
import efc from "../img/icons/storeIcons/efc.svg"
import pps from "../img/icons/storeIcons/pps.svg"
import ppsHr from "../img/icons/storeIcons/pps-hr.svg"

const serviceName = '#/ref-app/'

let vh = window.innerHeight * 0.01;
// Устанавливаем значение в пользовательском свойстве --vh в корень документа.
document.documentElement.style.setProperty('--vh', `${vh}px`);

const servicesByOrder = {
  'corporate': 0,
  'it-services': 1,
  'educational': 2,
  "scientific": 3,
  "financial": 4,
  innovative: 5,
  library: 6,
}

const sortByServiceAndByName = (a, b) => {
  return (servicesByOrder[a.categoryClass] - servicesByOrder[b.categoryClass])
    || a.title.localeCompare(b.title)
}

let storeIcons = [
  {title: 'Профиль', categoryClass: 'corporate', href: '#/profile', icon: profileIcon},
  {title: 'Каталог', categoryClass: 'corporate', href: '#/catalog', icon: catalogueIcon},
  {title: 'Новости', categoryClass: 'corporate', href: '#/news', icon: newsIcon},
  {title: 'Оргструктура', categoryClass: 'corporate', href: '#/org-structure', icon: orgStructureIcon},
  {title: 'Контакты', categoryClass: 'corporate', href: '#/contacts', icon: contactsIcon},
  {title: 'Сведения о вакцинации', categoryClass: 'corporate', href: '#/vac', icon: vacIcon},
  {title: 'Директорские совещания', categoryClass: 'corporate', href: `${serviceName}8`, icon: directSessions},
  {title: 'Cистема стимулирования НПР', categoryClass: 'corporate', href: `#/kpis-npr`, icon: ssNPR},
  {title: 'Эффективный контракт высших достижений', categoryClass: 'corporate', href: `#/kpis-effectivecontract`, icon: efc},
  {title: 'Уровень индивидуальных достижений НПР', categoryClass: 'corporate', href: `#/achievements-npr`, icon: achievementsNprIcon},
  {title: 'Служба техподдержки', categoryClass: 'corporate', href: `${serviceName}4`, icon: techSupport},
  {title: 'Корпоративная почта', categoryClass: 'corporate', href: `${serviceName}13`, icon: corpMailIcon},
  {title: 'Office 365', categoryClass: 'it-services', href: `${serviceName}5`, icon: office365},
  {title: 'LK-Role', categoryClass: 'it-services', href: `#/role`, icon: lkRole},
  {title: 'LK-Role Тестовый', modeClass: 'dev', categoryClass: 'it-services', href: `#/role-test`, icon: lkRole},
  {title: 'Имперсонация', categoryClass: 'it-services', href: `${serviceName}12`, icon: impersonationIcon},
  {title: 'Сервис видео-конференций BigBlueButton', categoryClass: 'it-services', href: `${serviceName}20`, icon: bbbIcon},
  {title: 'Антивирус', categoryClass: 'it-services', href: `${serviceName}21`, icon: antivirusIcon},
  {title: 'Wi-Fi', categoryClass: 'it-services', href: `${serviceName}22`, icon: wifiIcon},
  {title: 'Общие папки подразделений и папки обмена данными', categoryClass: 'it-services', href: `${serviceName}23`, icon: folderIcon},
  {title: 'Университет', categoryClass: 'educational', href: `${serviceName}9`, icon: universityIcon},
  {title: 'Интеграция команд Модеус', categoryClass: 'educational', href: `#/icm`, icon: modeusIcon},
  {title: 'Электронная библиотека', categoryClass: 'library', href: `${serviceName}3`, icon: electronicLibrary},
  {title: 'Дополнительное образование', categoryClass: 'educational', href: `${serviceName}6`, icon: additionalEducation},
  {title: 'БРС', categoryClass: 'educational', href: `${serviceName}7`, icon: brsIcon},
  {title: 'Рейтинг внеучебной деятельности студентов', categoryClass: 'educational', href: `#/rvd`, icon: rvdIcon},
  {title: 'СДО Гиперметод', categoryClass: 'educational', href: `${serviceName}14`, icon: hupermethodIcon},
  {title: 'Управление портфелем образовательных программ', categoryClass: 'educational', href: `${serviceName}15`, icon: PortfolioManagementIcon},
  {title: 'Управление сайтами образовательных программ', categoryClass: 'educational', href: `${serviceName}16`, icon: OPSiteManageIcon},
  {title: 'Реестр заявок ДПО', categoryClass: 'educational', href: `${serviceName}17`, icon: requestDPOIcon},
  {title: 'Портал электронного обучения на базе Moodle', categoryClass: 'educational', href: `${serviceName}18`, icon: moodleIcon},
  {title: 'Рабочие программы модулей и дисциплин', categoryClass: 'educational', href: `${serviceName}19`, icon: WorkingModulesIcon},
  {title: 'Научная академическая мобильность', categoryClass: 'scientific', href: `#/scimobility`, icon: scimobilityIcon},
  {title: 'Приоритет 2030', categoryClass: 'scientific', href: `${serviceName}10`, icon: priorityIcon},
  {title: 'Доступ к научным ресурсам', categoryClass: 'scientific', href: `${serviceName}1`, icon: remote},
  {title: 'Подписка на электронные ресурсы и базы данных', categoryClass: 'library', href: `${serviceName}24`, icon: subscriptionIcon},
  {title: 'Антиплагиат', categoryClass: 'library', href: `${serviceName}25`, icon: antiplagiatIcon},
  {title: 'Опросы библиотеки', categoryClass: 'library', href: `${serviceName}26`, icon: librarySurveysIcon},
  {title: 'Периодические издания', categoryClass: 'library', href: `${serviceName}27`, icon: periodicalsIcon},
  {title: 'Финансы', categoryClass: 'financial', href: `${serviceName}2`, icon: finance},
  {title: "Сервис рассылки уведомлений", categoryClass: "it-services", href: '#/notifications', icon: notificationsIcon},
  {title: "Сервис рассылки уведомлений тестовый", modeClass: 'dev', categoryClass: "it-services", href: '#/notifications-test', icon: notificationsIcon},
  {title: "Управление проектами", categoryClass: "corporate", href: `${serviceName}28`, icon: projectIcon},
  {title: "Приглашение студентов на практику", categoryClass: "corporate", href: `${serviceName}29`, icon: practiceIcon},
  {title: "Аккредитационный мониторинг", categoryClass: "educational", href: `${serviceName}30`, icon: analyticsIcon},
  {title: "Создание почтового ящика", categoryClass: "it-services", href: `${serviceName}31`, icon: emailIcon},
  {title: "Получение лицензионного ПО", categoryClass: "it-services", href: `${serviceName}32`, icon: licenseIcon},
  {title: "Портал научных мероприятий", categoryClass: "scientific", href: `${serviceName}33`, icon: scienceIcon},
  {title: "Управление публикационной активностью", categoryClass: "scientific", href: `${serviceName}34`, icon: publicationsMonitoringIcon},
  {title: "Заключение о возможности опубликования", categoryClass: "library", href: `${serviceName}35`, icon: publicationsIcon},
  {title: "EBSCO Discovery", categoryClass: "library", href: `${serviceName}36`, icon: EBSCOIcon},
  {title: "Новости", categoryClass: "innovative", href: `${serviceName}37`, icon: InnoNewsIcon},
  {title: "Акселератор УрФУ", categoryClass: "innovative", href: `${serviceName}38`, icon: AceleratorIcon},
  {title: "Платформа университетского технологического предпринимательства", categoryClass: "innovative", href: `${serviceName}39`, icon: techIcon},
  {title: "Стартап как диплом", categoryClass: "innovative", href: `${serviceName}40`, icon: startupAsDiplomaIcon},
  {title: "Стартап-студия УрФУ", categoryClass: "innovative", href: `${serviceName}41`, icon: startupUrfuIcon},
  {title: "Центр интеллектуальной собственности УрФУ", categoryClass: "innovative", href: `${serviceName}42`, icon: copyrightIcon},
  {title: "Центр трансфера технологий УрФУ", categoryClass: "innovative", href: `${serviceName}43`, icon: centerIcon},
  {title: 'Конкурс ППС', categoryClass: 'corporate', group: 'corporate', href: `https://konkurs-pps.urfu.ru/`, icon: pps},
  {title: 'Конкурс ППС. Экспертная часть', categoryClass: 'corporate', group: 'corporate', href: `https://pps-hr.urfu.ru`, icon: ppsHr},
];

storeIcons.sort(sortByServiceAndByName);
if (!window.location.href.includes("-test.")) {
  storeIcons = storeIcons.filter(x => x.modeClass == null);
}

export default function Store(props) {
  const { handleClick } = props;
  const email = window.keycloak.tokenParsed ? window.keycloak.tokenParsed.user.person.email : '';
  return (
    <div className={window.isAuthenticated && window.isAuthenticated() ? 'u-store' : 'u-store logged-out'}>
      { window.isAuthenticated && window.isAuthenticated() 
        ?
          storeIcons.filter(item => item.href !== `#/icm` && !integrationOfModeusCommands?.includes(email) 
          || integrationOfModeusCommands?.includes(email)).map((item, i) =>
            <StoreTag key={i} title={item.title} modeClass={item.modeClass} categoryClass={item.categoryClass} href={item.href} icon={item.icon} blank={item.blank} handleClick={handleClick}/>
          ) 
        :
          <div className='unauthorized-container'>
            <p>Авторизуйтесь, чтобы получить больше сервисов</p>
              <StoreTag unauthorized title='Новости' categoryClass='corporate' icon={newsIcon} href='#/news' handleClick={handleClick}/>
          </div> 
      }
    </div>
  );
}
