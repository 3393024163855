import React from 'react';
import './Button.less';

export default function Button(props) {
  return (
    <div className={[`header-button ${props.shape ? `header-button-${props.shape}` : ''} ${props.size ? `header-button-${props.size}` : ''} ${props.clazz ? props.clazz : ''}`]}>
      <button className={props.avatar ? '' : 'no-image'} onClick={props.onClick}>
        {props.avatar ? <img src={props.avatar}/> : props.initials && props.auth ? (props.title ? props.title.lastName?.charAt(0).toUpperCase() + props.title.firstName?.charAt(0).toUpperCase()  : '') : (props.title ? props.title : '')}
      </button>
      {props.img ? <img src={props.img}/> : null}
    </div>
  );
}