// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,0BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF","sourcesContent":["@headerHight: 65px;\n\n.loader {\n  width: 100%;\n  height: calc(100vh - @headerHight);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
