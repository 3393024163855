import React from 'react';  
import { createRoot } from 'react-dom/client';  
import Header from './Header';  
// import Footer from './Footer';

const UpperLayout = () => (  
  <>  
    <Header />  
    {/* <Footer /> */}  
  </>  
);  

const render = () => {  
  const rootElement = document.getElementById('UpperLayout');  
  const root = createRoot(rootElement);  
  root.render(<UpperLayout />);  
};  

export default render;