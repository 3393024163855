// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*media queries*/
.profile-dropdown {
  text-align: center;
}
.profile-dropdown .tip {
  font-size: 12px;
}
.profile-dropdown .avatar {
  width: 63.4px;
  height: 63.4px;
  border-radius: 50%;
  line-height: 63.4px;
  font-size: 30px;
  color: #1E4391;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid white;
}
.profile-dropdown .heading {
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
}
.profile-dropdown .body {
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
}
.profile-dropdown .footer {
  padding: 5px 14px;
}
.user-image-wrapper {
  width: 67.4px;
  height: 67.4px;
  background: #D3D3D3;
  border: 2px solid #1E4391;
  border-radius: 50%;
  line-height: 67.4px;
  font-size: 40px;
  text-align: center;
  position: relative;
  top: 2.3px;
  left: 2.3px;
  margin: 0 auto 16px;
}
.user-image-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.user-name {
  padding-top: 12px;
  color: #1E4391;
}
.user-name a {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProfileDropdown.less"],"names":[],"mappings":"AAAA,gBAAgB;AAEhB;EACE,kBAAA;AAAF;AADA;EAGI,eAAA;AACJ;AAJA;EAMI,aAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,uBAAA;AACJ;AAhBA;EAkBI,aAAA;EACA,gCAAA;AACJ;AApBA;EAsBI,aAAA;EACA,gCAAA;AACJ;AAxBA;EA0BI,iBAAA;AACJ;AAKI;EACE,aAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;AAHN;AATI;EAcI,WAAA;EACA,YAAA;EACA,kBAAA;AAFR;AAME;EACE,iBAAA;EACA,cAAA;AAJJ;AAEE;EAII,gBAAA;EACA,eAAA;EACA,iBAAA;AAHN","sourcesContent":["@import \"../style/const\";\n@size: 63.4px;\n.profile-dropdown {\n  text-align: center;\n  .tip {\n    font-size: 12px;\n  }\n  .avatar {\n    width: @size;\n    height: @size;\n    border-radius: 50%;\n    line-height: @size;\n    font-size: 30px;\n    color: #1E4391;\n    position: absolute;\n    top: 0;\n    left: 0;\n    border: 2px solid white;\n  }\n  .heading {\n    padding: @spacing;\n    border-bottom: @border;\n  }\n  .body {\n    padding: @spacing;\n    border-bottom: @border;\n  }\n  .footer {\n    padding: 5px 14px;\n  }\n}\n\n.user {\n  &-image {\n    &-wrapper {\n      width: @size + 4px;\n      height: @size + 4px;\n      background: #D3D3D3;\n      border: 2px solid #1E4391;\n      border-radius: 50%;\n      line-height: @size + 4px;\n      font-size: 40px;\n      text-align: center;\n      position: relative;\n      top: 2.3px;\n      left: 2.3px;\n      margin: 0 auto 16px;\n      img {\n        width: 100%;\n        height: 100%;\n        border-radius: 50%;\n      }\n    }\n  }\n  &-name {\n    padding-top: 12px;\n    color: #1E4391;\n    a {\n      font-weight: 400;\n      font-size: 12px;\n      line-height: 15px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
