import React from 'react';
import { createRoot } from 'react-dom/client';
import Loader from "../src/components/Loader";

function Render(props) {
  const { loading } = props;

  return (
    <>
      {loading && <Loader/>}
      <div id="subapp-viewport" />
    </>
  );
}

var root;

export default function render({ loading }) {
  if(!root) {
    const container = document.getElementById('subapp-container');
    root = createRoot(container);
  }
  
  root.render(<Render loading={loading} />);
}