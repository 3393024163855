import React, { useEffect, useState, useRef } from 'react';
import './Dropdown.less';

let useOnClickOutside = (callback) => {
  let containerRef = useRef(null);

  useEffect(() => {
    function onClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', onClickOutside);
    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  });

  return containerRef;
};

export default ({ trigger, setClose, content, on: initialOn = false }) => {
  let [on, setOn] = useState(initialOn);

  function toggle() {
    setOn((on) => !on);
  }

  function close() {
    setOn(false);
  }

  setClose && setClose(() => { close() });

  let containerRef = useOnClickOutside(close);

  return (
    <>
      <div className="u-dropdown-container" ref={containerRef}>
        <span className="u-dropdown-trigger" onClick={toggle}>
          {trigger}
        </span>
        {on ? <div className="u-dropdown-content">{content}</div> : null}
      </div>
    </>
  );
};
