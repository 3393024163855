import React from 'react';
import loader from '../img/spinning-circles.svg'
import './Loader.less'

export default function Loader() {
  return (
    <div className='loader'>
      <img src={loader} alt=""/>
    </div>
  );
}
