import React, { Component } from 'react';
import Button from './Button';
import './ProfileDropdown.less'

export default class ProfileDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { keycloak: window.keycloak, authenticated: window.isAuthenticated && window.isAuthenticated() };

    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  signIn() {
    if(this.state.keycloak) {
      this.state.keycloak.login();
    }
  }

  signOut() {
    if(this.state.keycloak) {
      this.state.keycloak.logout({ redirectUri: window.location.origin });
    }
  }

  render() {
    if (this.state.authenticated) {
      return (
        <div className='profile-dropdown'>
          <div className="heading">
              <div className="user-image-wrapper">
                {this.props.avatar ? <img className='avatar' src={this.props.avatar}/> : <div className='avatar'>{userTitle()?.lastName.charAt(0).toUpperCase() + userTitle()?.firstName.charAt(0).toUpperCase()}</div>}
              </div>
            <div className='user-name'><a href="#/profile" onClick={this.props.handleClick}>{userTitle()?.lastName + ' ' + userTitle()?.firstName}</a></div>
          </div>
          {/* <div className="body">
            <a href="#/profile" onClick={this.props.handleClick}>Профиль</a>
          </div> */}
          <div className="footer">
            <Button title='Выйти' clazz="logout" onClick={this.signOut}/>
          </div>
        </div>
      )
    } else return (
      <div className='profile-dropdown'>
        <div className="body">
          <p className='tip'>Пожалуйста, зайдите в свой профиль</p>
        </div>
        <div className="footer">
          <Button title='Войти' clazz="login" onClick={this.signIn}/>
        </div>
      </div>
    );
  }
}