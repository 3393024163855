import React from 'react';
import './StoreTag.less';

export default function StoreTag(props) {
  const {title, href, icon, blank, handleClick, categoryClass, modeClass, unauthorized} = props;
  return (
    <a style={unauthorized ? {marginTop: 16, marginBottom: 16} : {marginTop: 0, marginBottom: 0}} href={href} className={modeClass === 'dev' ? 'u-store-tag for-dev' : 'u-store-tag'} target={blank&&'_blank'} onClick={handleClick}>
      <div className='u-store-tag-image'>
        <img src={icon} alt={title}/>
      </div>
      <div className={`u-store-tag-title ${categoryClass}`}>{title}</div>
    </a>
  );
}